import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

type LoadingPlaceholderProps = {
    text?: string,
    progressColor?: 'inherit' | 'secondary' | 'primary' | 'error' | 'info' | 'success' | 'warning',
    size?: number,
    inline?: boolean
}

export const LoadingPlaceholder: React.FC<LoadingPlaceholderProps> = ({ text, inline, progressColor, size }) => {
  return (
    <Box
      display='flex'
      flexDirection={inline ? 'row' : 'column'}
      justifyContent='center'
      alignItems='center'
    >
      <CircularProgress size={size ?? 40} color={progressColor ?? 'secondary'} />
      <Typography>{text ?? 'Loading'}</Typography>
    </Box>
  );
};
