import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider
} from '@mui/material';
import ReportIcon from '@mui/icons-material/Report';
import styles from './ErrorModal.module.css';

type ErrorModalProps = {
    title: string,
    onClose: () => void,
    message: string | React.ReactNode,
    closeButtonText?: string,
    open: boolean
}

export const ErrorModal: React.FC<ErrorModalProps> = ({
  title,
  open,
  message,
  closeButtonText,
  onClose
}) => {

  return (
    <Dialog
      className={styles.modal}
      sx={{ borderRadius: '10px' }}
      open={open}
      onClose={onClose}
    >
      <div className={styles.topLine}></div>
      <div className={styles.contentContainer}>
        <div className={styles.iconContainer}>
          <ReportIcon sx={{ fontSize: '85px', color: '#C33' }} />
        </div>
        <div>
          <DialogTitle className={styles.title}>
            <span className={styles.text}>{title}</span>
          </DialogTitle>
          <DialogContent className={styles.content}>
            <DialogContentText className={styles.text}>
              {message}
            </DialogContentText>
          </DialogContent>
        </div>
      </div>
      <Divider sx={{ width: '100%' }} />
      <DialogActions className={styles.dialogActions}>
        <Button onClick={onClose}>
          {closeButtonText ? closeButtonText : 'Cancel'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
