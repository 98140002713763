export const fetchJson = async <Response>(url: string, init?: RequestInit) => {
  /* console.log('request:' + url) */

  const response = await fetch(url, {
    ...init,
    headers: {
      ...init?.headers,
      'Content-Type': 'application/json'
    }
  });

  if (!response.ok) {
    const text = await response.text();
    const status = response.status;
    console.error({ text, status });

    if (response.status === 403) {
      throw { statusCode: 403, message: 'Permission denied. You are not authorized to perform this action.' };
    }

    throw new Error(text);
  }

  try {
    return await response.json() as Promise<Response>;
  } catch (error) {
    return {} as Promise<Response>;
  }
};

export const createFormData = (items: Record<string, string | Blob>) => {
  const formData = new FormData();
  for (const [name, value] of Object.entries(items)) {
    formData.append(name, value);
  }
  return formData;
};

export const wrapWithJsonHandling = async <ResponseData>(response: Response) => {
  if (!response.ok) {
    const text = await response.text();
    const status = response.status;
    console.error({ text, status });

    if (response.status === 403) {
      throw { statusCode: 403, message: 'Permission denied. You are not authorized to perform this action.' };
    }

    throw new Error(text);
  }

  try {
    return await response.json() as Promise<ResponseData>;
  } catch (error) {
    return {} as Promise<ResponseData>;
  }
};

export const fetchString = async <Response>(url: string, init?: RequestInit) => {

  return await fetch(url, {
    ...init,
    headers: {
      ...init?.headers,
      'Content-Type': 'text/plain'
    }
  }).then(async (response) => {

    if (!response.ok) {
      if (response.status === 403) {
        throw { statusCode: 403, message: 'Permission denied. You are not authorized to perform this action.' };
      }

      return response.json().then(text => {
        throw text;
      });
    }

    if (response.status >= 400 && response.status < 600) {
      throw new Error('Bad response from server');
    }

    try {
      return await response.text();
    } catch (error) {
      return {} as Promise<Response>;
    }

  }).catch((error) => {
    throw error;
  });
};

export const fetchNoReturn = async <Response>(url: string, init?: RequestInit) => {

  const response = await fetch(url, {
    ...init,
    headers: {
      ...init?.headers,
      'Content-Type': 'application/json'
    }
  });

  const responseBody = await response.text();

  if (response.ok) {
    const json = responseBody === '' ? {} : JSON.parse(responseBody);
    return json as Promise<Response>;
  }

  let errorMessage;

  try {
    const errorJson = JSON.parse(responseBody);
    errorMessage = errorJson.error;
  } catch {
    errorMessage = responseBody;
  }

  throw new Error(errorMessage);
};

export const fetchFile = async <Response>(url: string, init?: RequestInit) => {
  const response = await fetch(url, {
    ...init,
    headers: {
      ...init?.headers,
      'Content-Type': 'application/json'
    }
  });

  if (!response.ok) {
    try {
      throw await response.json();
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  const content = await response.blob();
  const contentDisposition = response.headers.get('Content-Disposition');
  const matches = /filename\*?=(UTF-8(['']))?([^;]*)?/.exec(contentDisposition || '');

  let fileName = null;
  if (matches) {
    fileName = matches[3];
    fileName = fileName.replace(/["']/g, '');
    fileName = decodeURIComponent(fileName);
  }

  return { fileName: fileName ?? '', content };
};

type Params<T = unknown> = Record<string, T>

export const uploadSingleFile = async <Response>(
  url: string,
  file: File,
  init?: RequestInit,
  params?: Params
) => {
  if (!file) {
    throw new Error('Request error in uploadSingleFile');
  }

  const formData = new FormData();
  formData.append('file', file as File);

  if (params && Object.keys(params).length) {
    for (const [key, value] of Object.entries(params)) {
      formData.append(key, value as string);
    }
  }

  const response = await fetch(url, {
    method: init?.method ?? 'POST',
    body: formData,
    headers: {
      ...init?.headers
    }
  });

  if (!response.ok) {
    try {
      throw { statusCode: response.status, message: await response.json() };
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  return response.json() as Promise<Response>;
};

export const uploadImage = async <Response>(url: string, image: File, init?: RequestInit) => {

  if (!image) {
    throw new Error('Request error in uploadImage');
  }


  return await fetch(url, {
    method: init?.method ?? 'POST',
    body: image,
    headers: {
      ...init?.headers
    }
  })
    .then((response) => {
      if (!response.ok) {
        return response.text().then(text => {
          console.log(text);
          throw new Error(text);
        });
      }

      if (response.status >= 400 && response.status < 600) {
        throw new Error('Bad response from server');
      }

      return response.text() as Promise<Response>;

    }).catch((error) => {

      throw new Error(error);
    });
};

export const uploadReportWithDate = async <Response>(url: string, file: File, reportDate: string, init?: RequestInit) => {

  if (!file) {
    throw new Error('Request error in uploadSingleFile');
  }

  const formData = new FormData();

  formData.append('file', file as File);
  formData.append('reportDate', reportDate);

  return await fetch(url, {
    method: init?.method ?? 'POST',
    body: formData,
    headers: {
      ...init?.headers
    }
  })
    .then((response) => {
      if (!response.ok) {
        return response.text().then(text => {
          console.log(text);
          throw new Error(text);
        });
      }

      if (response.status >= 400 && response.status < 600) {
        throw new Error('Bad response from server');
      }

      return response.json() as Promise<Response>;

    }).catch((error) => {

      throw new Error(error);
    });
};

