import { Configuration, RedirectRequest } from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AD_APP_CLIENT_ID!,
    authority: 'https://login.microsoftonline.com/' + process.env.REACT_APP_AD_TENANT_ID,
    redirectUri: process.env.REACT_APP_REDIRECT_URI
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false
  }
};

export const loginRequest: RedirectRequest = {
  scopes: ['User.Read']
};

export const tokenRequestScopes = ['api://' + process.env.REACT_APP_AD_APP_CLIENT_ID + '/All.Read'];


export const PUBLIC_ROUTE_PATH = 'public';

export enum ROLES_LIST {
    ROLE_SUPERUSER = 'ROLE_SUPERUSER',
    ROLE_KIMEP_ADMIN = 'ROLE_KIMEP_ADMIN',
    ROLE_ELECTION_ADMIN_GLOBAL = 'ROLE_ELECTION_ADMIN_GLOBAL',
    ROLE_ELECTION_ADMIN_REGIONAL = 'ROLE_ELECTION_ADMIN_REGIONAL',
    ROLE_ELECTION_VIEWER_REGIONAL = 'ROLE_ELECTION_VIEWER_REGIONAL',
    ROLE_EMT_ROSTER_ADMIN_GLOBAL = 'ROLE_EMT_ROSTER_ADMIN_GLOBAL',
    ROLE_EMT_ROSTER_ADMIN_REGIONAL = 'ROLE_EMT_ROSTER_ADMIN_REGIONAL',
    ROLE_EMT_SECRETARIAT_GLOBAL = 'ROLE_EMT_SECRETARIAT_GLOBAL',
    ROLE_EMT_SECRETARIAT_REGIONAL = 'ROLE_EMT_SECRETARIAT_REGIONAL',
    ROLE_EMT_ROSTER_VIEWER_REGIONAL = 'ROLE_EMT_ROSTER_VIEWER_REGIONAL',
    ROLE_EMT_ROSTER_VIEWER_GLOBAL = 'ROLE_EMT_ROSTER_VIEWER_GLOBAL',
    ROLE_GOVERNANCE_STRUCTURE_ADMIN_GLOBAL = 'ROLE_GOVERNANCE_STRUCTURE_ADMIN_GLOBAL',
    ROLE_GOVERNANCE_STRUCTURE_ADMIN_REGIONAL = 'ROLE_GOVERNANCE_STRUCTURE_ADMIN_REGIONAL',
    ROLE_GOVERNANCE_STRUCTURE_USER = 'ROLE_GOVERNANCE_STRUCTURE_USER',
    ROLE_MENTOR = 'ROLE_MENTOR',
    ROLE_NFP = 'ROLE_NFP',
    ROLE_TFP = 'ROLE_TFP',
    ROLE_CLASSIFICATION_ADMIN_GLOBAL = 'ROLE_CLASSIFICATION_ADMIN_GLOBAL',
    ROLE_CLASSIFICATION_ADMIN_REGIONAL = 'ROLE_CLASSIFICATION_ADMIN_REGIONAL',
    ROLE_TRAINING_NFP = 'ROLE_TRAINING_NFP',
    ROLE_TRAINING_EMTCC = 'ROLE_TRAINING_EMTCC',
    ROLE_TRAINING_EMT = 'ROLE_TRAINING_EMT',
    ROLE_CLASSIFICATION_TFP = 'ROLE_CLASSIFICATION_TFP',
    ROLE_CLASSIFICATION_VIEWER_GLOBAL = 'ROLE_CLASSIFICATION_VIEWER_GLOBAL',
    ROLE_CLASSIFICATION_VIEWER_REGIONAL = 'ROLE_CLASSIFICATION_VIEWER_REGIONAL',
    ROLE_REGIONAL_FP = 'ROLE_REGIONAL_FP',
    ROLE_RESCEU_VIEWER = 'ROLE_RESCEU_VIEWER',
    ROLE_DEPLOYMENT_ADMIN = 'ROLE_DEPLOYMENT_ADMIN',
    ROLE_DEPLOYMENT_EMT = 'ROLE_DEPLOYMENT_EMT',
    ROLE_DEPLOYMENT_VIEWER = 'ROLE_DEPLOYMENT_VIEWER',
}

export enum PERMISSIONS_LIST {
    PERSON_VIEW_FEEDBACK = 'PERSON_VIEW_FEEDBACK',
    AUDIT_VIEW_ALL_EVENTS = 'AUDIT_VIEW_ALL_EVENTS',
    AUTH_VIEW_ALL_USERS = 'AUTH_VIEW_ALL_USERS',
    AUTH_VIEW_REGIONAL_USERS = 'AUTH_VIEW_REGIONAL_USERS',
    AUTH_EDIT_PERMISSIONS = 'AUTH_EDIT_PERMISSIONS',
    CLASSIFICATION_ACCEPTANCE_LETTER = 'CLASSIFICATION_ACCEPTANCE_LETTER',
    CLASSIFICATION_ACCESS_CLASSIFICATION_DASHBOARD = 'CLASSIFICATION_ACCESS_CLASSIFICATION_DASHBOARD',
    CLASSIFICATION_ACCESS_REGIONAL_CLASSIFICATION_DASHBOARD = 'CLASSIFICATION_ACCESS_REGIONAL_CLASSIFICATION_DASHBOARD',
    CLASSIFICATION_APPROVE_EOI = 'CLASSIFICATION_APPROVE_EOI',
    CLASSIFICATION_APPROVE_REGIONAL_EOI = 'CLASSIFICATION_APPROVE_REGIONAL_EOI',
    CLASSIFICATION_CANCEL_PROCESS = 'CLASSIFICATION_CANCEL_PROCESS',
    CLASSIFICATION_CEREMONY = 'CLASSIFICATION_CEREMONY',
    CLASSIFICATION_CLASSIFIED = 'CLASSIFICATION_CLASSIFIED',
    CLASSIFICATION_CREATE_EOI = 'CLASSIFICATION_CREATE_EOI',
    CLASSIFICATION_CREATE_MENTOR_PROFILE = 'CLASSIFICATION_CREATE_MENTOR_PROFILE',
    CLASSIFICATION_CREATE_MENTORED_PROCESSES_VISITS = 'CLASSIFICATION_CREATE_MENTORED_PROCESSES_VISITS',
    CLASSIFICATION_CREATE_REGIONAL_EOI = 'CLASSIFICATION_CREATE_REGIONAL_EOI',
    CLASSIFICATION_CREATE_REGIONAL_MENTOR_PROFILE = 'CLASSIFICATION_CREATE_REGIONAL_MENTOR_PROFILE',
    CLASSIFICATION_CREATE_REGIONAL_VISIT = 'CLASSIFICATION_CREATE_REGIONAL_VISIT',
    CLASSIFICATION_CREATE_VISIT = 'CLASSIFICATION_CREATE_VISIT',
    CLASSIFICATION_FIRST_VISIT = 'CLASSIFICATION_FIRST_VISIT',
    CLASSIFICATION_INITIAL_INTERVIEW = 'CLASSIFICATION_INITIAL_INTERVIEW',
    CLASSIFICATION_MEMORANDUM = 'CLASSIFICATION_MEMORANDUM',
    CLASSIFICATION_MENTOR_ALLOCATED = 'CLASSIFICATION_MENTOR_ALLOCATED',
    CLASSIFICATION_MENTORED_PROCESSES_VISIT = 'CLASSIFICATION_MENTORED_PROCESSES_VISIT',
    CLASSIFICATION_MENTORSHIP = 'CLASSIFICATION_MENTORSHIP',
    CLASSIFICATION_ONLINE_DOCUMENT_REVIEW = 'CLASSIFICATION_ONLINE_DOCUMENT_REVIEW',
    CLASSIFICATION_PRE_VERIFICATION_APPROVED = 'CLASSIFICATION_PRE_VERIFICATION_APPROVED',
    CLASSIFICATION_PRE_VERIFICATION_PLANNED = 'CLASSIFICATION_PRE_VERIFICATION_PLANNED',
    CLASSIFICATION_PRE_VERIFICATION_REQUEST = 'CLASSIFICATION_PRE_VERIFICATION_REQUEST',
    CLASSIFICATION_PRE_VERIFICATION_VISIT = 'CLASSIFICATION_PRE_VERIFICATION_VISIT',
    CLASSIFICATION_REGISTRATION = 'CLASSIFICATION_REGISTRATION',
    CLASSIFICATION_RESOLVE_MESSAGES = 'CLASSIFICATION_RESOLVE_MESSAGES',
    CLASSIFICATION_SA_REVIEW = 'CLASSIFICATION_SA_REVIEW',
    CLASSIFICATION_SA_SUBMIT = 'CLASSIFICATION_SA_SUBMIT',
    CLASSIFICATION_SA_SUBMIT_MENTOR_ALLOCATED_MIXED = 'CLASSIFICATION_SA_SUBMIT_MENTOR_ALLOCATED_MIXED',
    CLASSIFICATION_SA_VALIDATE = 'CLASSIFICATION_SA_VALIDATE',
    CLASSIFICATION_SA_VALIDATE_FIRST_VISIT_MIXED = 'CLASSIFICATION_SA_VALIDATE_FIRST_VISIT_MIXED',
    CLASSIFICATION_SA_VALIDATE_WORKPLAN_MIXED = 'CLASSIFICATION_SA_VALIDATE_WORKPLAN_MIXED',
    CLASSIFICATION_TEAM_CREDENTIALS = 'CLASSIFICATION_TEAM_CREDENTIALS',
    CLASSIFICATION_UPDATE_EOI = 'CLASSIFICATION_UPDATE_EOI',
    CLASSIFICATION_UPDATE_MENTOR_PROFILE = 'CLASSIFICATION_UPDATE_MENTOR_PROFILE',
    CLASSIFICATION_UPDATE_MENTORED_PROCESSES_VISIT = 'CLASSIFICATION_UPDATE_MENTORED_PROCESSES_VISIT',
    CLASSIFICATION_UPDATE_MY_MENTOR_PROFILE = 'CLASSIFICATION_UPDATE_MY_MENTOR_PROFILE',
    CLASSIFICATION_UPDATE_PROCESS = 'CLASSIFICATION_UPDATE_PROCESS',
    CLASSIFICATION_UPDATE_REGIONAL_EOI = 'CLASSIFICATION_UPDATE_REGIONAL_EOI',
    CLASSIFICATION_UPDATE_REGIONAL_MENTOR_PROFILE = 'CLASSIFICATION_UPDATE_REGIONAL_MENTOR_PROFILE',
    CLASSIFICATION_UPDATE_REGIONAL_PROCESS = 'CLASSIFICATION_UPDATE_REGIONAL_PROCESS',
    CLASSIFICATION_UPDATE_REGIONAL_VISIT = 'CLASSIFICATION_UPDATE_REGIONAL_VISIT',
    CLASSIFICATION_UPDATE_VISIT = 'CLASSIFICATION_UPDATE_VISIT',
    CLASSIFICATION_VERIFICATION_APPROVED = 'CLASSIFICATION_VERIFICATION_APPROVED',
    CLASSIFICATION_VERIFICATION_PLANNED = 'CLASSIFICATION_VERIFICATION_PLANNED',
    CLASSIFICATION_VERIFICATION_REPORT = 'CLASSIFICATION_VERIFICATION_REPORT',
    CLASSIFICATION_VERIFICATION_REQUEST = 'CLASSIFICATION_VERIFICATION_REQUEST',
    CLASSIFICATION_VERIFICATION_VISIT = 'CLASSIFICATION_VERIFICATION_VISIT',
    CLASSIFICATION_VIEW_ALL_EOIS = 'CLASSIFICATION_VIEW_ALL_EOIS',
    CLASSIFICATION_VIEW_ALL_MENTOR_PROFILES = 'CLASSIFICATION_VIEW_ALL_MENTOR_PROFILES',
    CLASSIFICATION_VIEW_ALL_VISITS = 'CLASSIFICATION_VIEW_ALL_VISITS',
    CLASSIFICATION_VIEW_AUDIT_DATA = 'CLASSIFICATION_VIEW_AUDIT_DATA',
    CLASSIFICATION_VIEW_COUNTRY_LEVEL_EOIS = 'CLASSIFICATION_VIEW_COUNTRY_LEVEL_EOIS',
    CLASSIFICATION_VIEW_COUNTRY_LEVEL_MENTOR_PROFILES = 'CLASSIFICATION_VIEW_COUNTRY_LEVEL_MENTOR_PROFILES',
    CLASSIFICATION_VIEW_COUNTRY_LEVEL_PROCESSES = 'CLASSIFICATION_VIEW_COUNTRY_LEVEL_PROCESSES',
    CLASSIFICATION_VIEW_COUNTRY_VISITS = 'CLASSIFICATION_VIEW_COUNTRY_VISITS',
    CLASSIFICATION_VIEW_MENTORED_EOIS = 'CLASSIFICATION_VIEW_MENTORED_EOIS',
    CLASSIFICATION_VIEW_MENTORED_PROCESSES = 'CLASSIFICATION_VIEW_MENTORED_PROCESSES',
    CLASSIFICATION_VIEW_MENTORED_PROCESSES_VISITS = 'CLASSIFICATION_VIEW_MENTORED_PROCESSES_VISITS',
    CLASSIFICATION_VIEW_MY_ORGANIZATION_VISITS = 'CLASSIFICATION_VIEW_MY_ORGANIZATION_VISITS',
    CLASSIFICATION_VIEW_MY_MENTOR_PROFILE = 'CLASSIFICATION_VIEW_MY_MENTOR_PROFILE',
    CLASSIFICATION_VIEW_ORGANIZATION_EOIS = 'CLASSIFICATION_VIEW_ORGANIZATION_EOIS',
    CLASSIFICATION_VIEW_ORGANIZATION_PROCESSES = 'CLASSIFICATION_VIEW_ORGANIZATION_PROCESSES',
    CLASSIFICATION_VIEW_PROCESSES = 'CLASSIFICATION_VIEW_PROCESSES',
    CLASSIFICATION_VIEW_REGIONAL_EOIS = 'CLASSIFICATION_VIEW_REGIONAL_EOIS',
    CLASSIFICATION_VIEW_REGIONAL_MENTOR_PROFILES = 'CLASSIFICATION_VIEW_REGIONAL_MENTOR_PROFILES',
    CLASSIFICATION_VIEW_REGIONAL_MENTOR_PROFILES_BY_LINK = 'CLASSIFICATION_VIEW_REGIONAL_MENTOR_PROFILES_BY_LINK',
    CLASSIFICATION_VIEW_REGIONAL_PROCESSES = 'CLASSIFICATION_VIEW_REGIONAL_PROCESSES',
    CLASSIFICATION_VIEW_REGIONAL_VISITS = 'CLASSIFICATION_VIEW_REGIONAL_VISITS',
    CLASSIFICATION_VIEW_MESSAGES = 'CLASSIFICATION_VIEW_MESSAGES',
    CLASSIFICATION_VIEW_ALL_MENTOR_PROFILES_FULL = 'CLASSIFICATION_VIEW_ALL_MENTOR_PROFILES_FULL',
    CLASSIFICATION_VIEW_REGIONAL_MENTOR_PROFILES_FULL = 'CLASSIFICATION_VIEW_REGIONAL_MENTOR_PROFILES_FULL',
    CLASSIFICATION_WORKPLAN = 'CLASSIFICATION_WORKPLAN',
    CLASSIFICATION_WORKPLAN_APPROVED = 'CLASSIFICATION_WORKPLAN_APPROVED',
    CLASSIFICATION_FOLDER_FILES_UPDATE = 'CLASSIFICATION_FOLDER_FILES_UPDATE',
    CLASSIFICATION_FOLDER_FILES_VIEW = 'CLASSIFICATION_FOLDER_FILES_VIEW',
    CLASSIFICATION_FOLDER_FILES_ADD = 'CLASSIFICATION_FOLDER_FILES_ADD',
    CLASSIFICATION_FOLDER_FILES_DELETE = 'CLASSIFICATION_FOLDER_FILES_DELETE',
    CLASSIFICATION_VIEW_GANTTCHART = 'CLASSIFICATION_VIEW_GANTTCHART',
    CLASSIFICATION_VIEW_GANTTCHART_REGIONAL = 'CLASSIFICATION_VIEW_GANTTCHART_REGIONAL',
    CLASSIFICATION_VIEW_RESCEU_EOIS = 'CLASSIFICATION_VIEW_RESCEU_EOIS',
    CLASSIFICATION_VIEW_RESCEU_PROCESSES = 'CLASSIFICATION_VIEW_RESCEU_PROCESSES',
    CLASSIFICATION_VIEW_RESCEU_PROCESS_VISITS = 'CLASSIFICATION_VIEW_RESCEU_PROCESS_VISITS',
    DEPLOYMENTS_ADD_REPORT = 'DEPLOYMENTS_ADD_REPORT',
    DEPLOYMENTS_ADD_MY_FIELDTEAM_REPORT = 'DEPLOYMENTS_ADD_MY_FIELDTEAM_REPORT',
    DEPLOYMENTS_CREATE_EOI = 'DEPLOYMENTS_CREATE_EOI',
    DEPLOYMENTS_CREATE_TASK = 'DEPLOYMENTS_CREATE_TASK',
    DEPLOYMENTS_CREATE_MYTEAM_EOI = 'DEPLOYMENTS_CREATE_MYTEAM_EOI',
    DEPLOYMENTS_DELETE_REPORT = 'DEPLOYMENTS_DELETE_REPORT',
    DEPLOYMENTS_DELETE_MY_FIELDTEAM_REPORT = 'DEPLOYMENTS_DELETE_MY_FIELDTEAM_REPORT',
    DEPLOYMENTS_VIEW_ALL_EMERGENCIES = 'DEPLOYMENTS_VIEW_ALL_EMERGENCIES',
    DEPLOYMENTS_VIEW_MY_TEAMS_EMERGENCIES = 'DEPLOYMENTS_VIEW_MY_TEAMS_EMERGENCIES',
    DEPLOYMENTS_CREATE_EMERGENCY = 'DEPLOYMENTS_CREATE_EMERGENCY',
    DEPLOYMENTS_UPDATE_EMERGENCY = 'DEPLOYMENTS_UPDATE_EMERGENCY',
    DEPLOYMENTS_VIEW_REPORTS = 'DEPLOYMENTS_VIEW_REPORTS',
    DEPLOYMENTS_VIEW_MY_FIELDTEAM_REPORTS = 'DEPLOYMENTS_VIEW_MY_FIELDTEAM_REPORTS',
    DEPLOYMENTS_VIEW_ALL_FIELDTEAMS = 'DEPLOYMENTS_VIEW_ALL_FIELDTEAMS',
    DEPLOYMENTS_VIEW_MY_AND_DEPLOYED_FIELDTEAMS = 'DEPLOYMENTS_VIEW_MY_AND_DEPLOYED_FIELDTEAMS',
    DEPLOYMENTS_VIEW_ALL_EOIS = 'DEPLOYMENTS_VIEW_ALL_EOIS',
    DEPLOYMENTS_VIEW_MY_EOIS = 'DEPLOYMENTS_VIEW_MY_EOIS',
    DEPLOYMENTS_VIEW_EMTCC = 'DEPLOYMENTS_VIEW_EMTCC',
    DEPLOYMENTS_VIEW_MY_FIELDTEAM_EMTCC = 'DEPLOYMENTS_VIEW_MY_FIELDTEAM_EMTCC',
    DEPLOYMENTS_APPROVE_EOI = 'DEPLOYMENTS_APPROVE_EOI',
    DEPLOYMENTS_UPDATE_FIELDDTEAMS = 'DEPLOYMENTS_UPDATE_FIELDDTEAMS',
    DEPLOYMENTS_UPDATE_MY_FIELDTEAM = 'DEPLOYMENTS_UPDATE_MY_FIELDTEAM',
    DEPLOYMENTS_UPDATE_TASK = 'DEPLOYMENTS_UPDATE_TASK',
    DEPLOYMENTS_UPDATE_MY_FIELDTEAM_TASK = 'DEPLOYMENTS_UPDATE_MY_FIELDTEAM_TASK',
    DEPLOYMENTS_UPDATE_FIELDTEAM_STAFFING = 'DEPLOYMENTS_UPDATE_FIELDTEAM_STAFFING',
    DEPLOYMENTS_UPDATE_MY_FIELDTEAM_STAFFING = 'DEPLOYMENTS_UPDATE_MY_FIELDTEAM_STAFFING',
    DEPLOYMENTS_UPDATE_FIELDTEAM_EQUIPMENT = 'DEPLOYMENTS_UPDATE_FIELDTEAM_EQUIPMENT',
    DEPLOYMENTS_UPDATE_MY_FIELDTEAM_EQUIPMENT = 'DEPLOYMENTS_UPDATE_MY_FIELDTEAM_EQUIPMENT',
    DEPLOYMENTS_ADD_TOOLKIT_DOCUMENT = 'DEPLOYMENTS_ADD_TOOLKIT_DOCUMENT',
    DEPLOYMENTS_VIEW_EMERGENCY_DASHBOARD = 'DEPLOYMENTS_VIEW_EMERGENCY_DASHBOARD',
    DEPLOYMENTS_VIEW_FIELDTEAM_DASHBOARD = 'DEPLOYMENTS_VIEW_FIELDTEAM_DASHBOARD',
    DEPLOYMENTS_VIEW_MY_FIELDTEAM_DASHBOARD = 'DEPLOYMENTS_VIEW_MY_FIELDTEAM_DASHBOARD',
    ELECTION_EDIT_CAMPAIGN = 'ELECTION_EDIT_CAMPAIGN',
    ELECTION_EDIT_NOMINATION_FORM = 'ELECTION_EDIT_NOMINATION_FORM',
    ELECTION_EDIT_REGIONAL_CAMPAIGN = 'ELECTION_EDIT_REGIONAL_CAMPAIGN',
    ELECTION_ENDORSE_CANDIDATE = 'ELECTION_ENDORSE_CANDIDATE',
    ELECTION_NOMINATE = 'ELECTION_NOMINATE',
    ELECTION_NOMINATION_REVIEW = 'ELECTION_NOMINATION_REVIEW',
    ELECTION_SELF_NOMINATE = 'ELECTION_SELF_NOMINATE',
    ELECTION_VIEW_CAMPAIGN = 'ELECTION_VIEW_CAMPAIGN',
    ELECTION_VIEW_CANDIDATE = 'ELECTION_VIEW_CANDIDATE',
    ELECTION_VIEW_FULL_VOTING_RESULTS = 'ELECTION_VIEW_FULL_VOTING_RESULTS',
    ELECTION_VIEW_NOMINATIONS_BY_USER = 'ELECTION_VIEW_NOMINATIONS_BY_USER',
    ELECTION_VIEW_NOMINEE = 'ELECTION_VIEW_NOMINEE',
    ELECTION_VIEW_PUBLISHED_CAMPAIGN = 'ELECTION_VIEW_PUBLISHED_CAMPAIGN',
    ELECTION_VIEW_REGIONAL_CAMPAIGN = 'ELECTION_VIEW_REGIONAL_CAMPAIGN',
    ELECTION_VOTE = 'ELECTION_VOTE',
    ELECTION_VOTE_ANY_POSITION = 'ELECTION_VOTE_ANY_POSITION',
    ELECTION_WITHDRAW_NOMINATION = 'ELECTION_WITHDRAW_NOMINATION',
    ELECTIONS_VIEW_AUDIT_DATA = 'ELECTIONS_VIEW_AUDIT_DATA',
    GOV_EDIT_ASSIGNMENTS = 'GOV_EDIT_ASSIGNMENTS',
    GOV_EDIT_REGIONAL_ASSIGNMENTS = 'GOV_EDIT_REGIONAL_ASSIGNMENTS',
    GOV_EDIT_REGIONAL_STRUCTURE = 'GOV_EDIT_REGIONAL_STRUCTURE',
    GOV_EDIT_STRUCTURE = 'GOV_EDIT_STRUCTURE',
    GOV_VIEW_FULL_STRUCTURE = 'GOV_VIEW_FULL_STRUCTURE',
    GOV_VIEW_STRUCTURE = 'GOV_VIEW_STRUCTURE',
    GOVERNANCE_VIEW_AUDIT_DATA = 'GOVERNANCE_VIEW_AUDIT_DATA',
    PERSON_ASSIGN_NFP = 'PERSON_ASSIGN_NFP',
    PERSON_ASSIGN_REGIONAL_NFP = 'PERSON_ASSIGN_REGIONAL_NFP',
    PERSON_CREATE_PROFILE = 'PERSON_CREATE_PROFILE',
    PERSON_CREATE_REGIONAL_PROFILE = 'PERSON_CREATE_REGIONAL_PROFILE',
    PERSON_DELETE_NFP_ASSIGNMENT = 'PERSON_DELETE_NFP_ASSIGNMENT',
    PERSON_DELETE_PROFILE = 'PERSON_DELETE_PROFILE',
    PERSON_DELETE_REGIONAL_NFP_ASSIGNMENT = 'PERSON_DELETE_REGIONAL_NFP_ASSIGNMENT',
    PERSON_DELETE_REGIONAL_PROFILE = 'PERSON_DELETE_REGIONAL_PROFILE',
    PERSON_EDIT_ALL_PROFILES = 'PERSON_EDIT_ALL_PROFILES',
    PERSON_EDIT_COUNTRY_LEVEL_PROFILES = 'PERSON_EDIT_COUNTRY_LEVEL_PROFILES',
    PERSON_EDIT_NFP_OWN_PROFILE = 'PERSON_EDIT_NFP_OWN_PROFILE',
    PERSON_EDIT_NFP_PROFILE = 'PERSON_EDIT_NFP_PROFILE',
    PERSON_EDIT_NFP_REGIONAL_PROFILE = 'PERSON_EDIT_NFP_REGIONAL_PROFILE',
    PERSON_EDIT_OWN_PROFILE = 'PERSON_EDIT_OWN_PROFILE',
    PERSON_EDIT_REGIONAL_PROFILES = 'PERSON_EDIT_REGIONAL_PROFILES',
    PERSON_FULL_EDIT_PROFILES = 'PERSON_FULL_EDIT_PROFILES',
    PERSON_FULL_EDIT_REGIONAL_PROFILES = 'PERSON_FULL_EDIT_REGIONAL_PROFILES',
    PERSON_VIEW_ALL_COUNTRIES = 'PERSON_VIEW_ALL_COUNTRIES',
    PERSON_VIEW_ALL_PROFILES = 'PERSON_VIEW_ALL_PROFILES',
    PERSON_VIEW_AUDIT_DATA = 'PERSON_VIEW_AUDIT_DATA',
    PERSON_VIEW_COUNTRY_LEVEL_PROFILES = 'PERSON_VIEW_COUNTRY_LEVEL_PROFILES',
    PERSON_VIEW_NFP_PROFILE = 'PERSON_VIEW_NFP_PROFILE',
    PERSON_VIEW_NFP_REGIONAL_PROFILE = 'PERSON_VIEW_NFP_REGIONAL_PROFILE',
    PERSON_VIEW_REGIONAL_COUNTRIES = 'PERSON_VIEW_REGIONAL_COUNTRIES',
    PERSON_VIEW_REGIONAL_PROFILES = 'PERSON_VIEW_REGIONAL_PROFILES',
    PERSON_VIEW_REGIONAL_PROFILES_BY_LINK = 'PERSON_VIEW_REGIONAL_PROFILES_BY_LINK',
    PERSON_VIEW_SHORT_INFO = 'PERSON_VIEW_SHORT_INFO',
    TEAMS_ACCESS_DASHBOARD = 'TEAMS_ACCESS_DASHBOARD',
    TEAMS_ACCESS_REGIONAL_DASHBOARD = 'TEAMS_ACCESS_REGIONAL_DASHBOARD',
    TEAMS_CREATE_ORGANIZATION = 'TEAMS_CREATE_ORGANIZATION',
    TEAMS_CREATE_REGIONAL_ORGANIZATION = 'TEAMS_CREATE_REGIONAL_ORGANIZATION',
    TEAMS_CREATE_REGIONAL_TEAM = 'TEAMS_CREATE_REGIONAL_TEAM',
    TEAMS_CREATE_TEAM = 'TEAMS_CREATE_TEAM',
    TEAMS_DELETE_ORGANIZATION = 'TEAMS_DELETE_ORGANIZATION',
    TEAMS_DELETE_REGIONAL_ORGANIZATION = 'TEAMS_DELETE_REGIONAL_ORGANIZATION',
    TEAMS_DELETE_REGIONAL_TEAM = 'TEAMS_DELETE_REGIONAL_TEAM',
    TEAMS_DELETE_TEAM = 'TEAMS_DELETE_TEAM',
    TEAMS_DEPLOYMENT_ADD_MY_REPORT = 'TEAMS_DEPLOYMENT_ADD_MY_REPORT',
    TEAMS_DEPLOYMENT_ADD_REPORT = 'TEAMS_DEPLOYMENT_ADD_REPORT',
    TEAMS_DEPLOYMENT_DELETE_MY_REPORTS = 'TEAMS_DEPLOYMENT_DELETE_MY_REPORTS',
    TEAMS_DEPLOYMENT_DELETE_REPORTS = 'TEAMS_DEPLOYMENT_DELETE_REPORTS',
    TEAMS_DEPLOYMENT_EDIT_MY_PROFILE = 'TEAMS_DEPLOYMENT_EDIT_MY_PROFILE',
    TEAMS_DEPLOYMENT_EDIT_PROFILE = 'TEAMS_DEPLOYMENT_EDIT_PROFILE',
    TEAMS_DEPLOYMENT_GET_ALL_PROFILES = 'TEAMS_DEPLOYMENT_GET_ALL_PROFILES',
    TEAMS_DEPLOYMENT_GET_COUNTRY_LEVEL_PROFILES = 'TEAMS_DEPLOYMENT_GET_COUNTRY_LEVEL_PROFILES',
    TEAMS_DEPLOYMENT_GET_MY_PROFILES = 'TEAMS_DEPLOYMENT_GET_MY_PROFILES',
    TEAMS_DEPLOYMENT_VIEW_MY_REPORTS = 'TEAMS_DEPLOYMENT_VIEW_MY_REPORTS',
    TEAMS_DEPLOYMENT_VIEW_REPORTS = 'TEAMS_DEPLOYMENT_VIEW_REPORTS',
    TEAMS_EDIT_ORGANIZATION = 'TEAMS_EDIT_ORGANIZATION',
    TEAMS_EDIT_OWN_ORGANIZATION = 'TEAMS_EDIT_OWN_ORGANIZATION',
    TEAMS_EDIT_OWN_TEAM = 'TEAMS_EDIT_OWN_TEAM',
    TEAMS_EDIT_REGIONAL_ORGANIZATION = 'TEAMS_EDIT_REGIONAL_ORGANIZATION',
    TEAMS_EDIT_REGIONAL_TEAM = 'TEAMS_EDIT_REGIONAL_TEAM',
    TEAMS_EDIT_TEAM = 'TEAMS_EDIT_TEAM',
    TEAMS_FULL_EDIT_ORGANIZATION = 'TEAMS_FULL_EDIT_ORGANIZATION',
    TEAMS_FULL_EDIT_REGIONAL_ORGANIZATION = 'TEAMS_FULL_EDIT_REGIONAL_ORGANIZATION',
    TEAMS_FULL_EDIT_REGIONAL_TEAM = 'TEAMS_FULL_EDIT_REGIONAL_TEAM',
    TEAMS_FULL_EDIT_TEAM = 'TEAMS_FULL_EDIT_TEAM',
    TEAMS_VIEW_ALL_ORGANIZATIONS = 'TEAMS_VIEW_ALL_ORGANIZATIONS',
    TEAMS_VIEW_ALL_TEAMS = 'TEAMS_VIEW_ALL_TEAMS',
    TEAMS_VIEW_AUDIT_DATA = 'TEAMS_VIEW_AUDIT_DATA',
    TEAMS_VIEW_COUNTRY_LEVEL_ORGANIZATIONS = 'TEAMS_VIEW_COUNTRY_LEVEL_ORGANIZATIONS',
    TEAMS_VIEW_COUNTRY_LEVEL_TEAMS = 'TEAMS_VIEW_COUNTRY_LEVEL_TEAMS',
    TEAMS_VIEW_REGIONAL_ORGANIZATIONS = 'TEAMS_VIEW_REGIONAL_ORGANIZATIONS',
    TEAMS_VIEW_REGIONAL_ORGANIZATIONS_BY_LINK = 'TEAMS_VIEW_REGIONAL_ORGANIZATIONS_BY_LINK',
    TEAMS_VIEW_REGIONAL_TEAMS = 'TEAMS_VIEW_REGIONAL_TEAMS',
    TEAMS_VIEW_REGIONAL_TEAMS_BY_LINK = 'TEAMS_VIEW_REGIONAL_TEAMS_BY_LINK',
    TEAMS_VIEW_RESCEU_TEAMS = 'TEAMS_VIEW_RESCEU_TEAMS',
    TEAMS_VIEW_RESCEU_STAFFING = 'TEAMS_VIEW_RESCEU_STAFFING',
    TEAMS_VIEW_RESCEU_EQUIPMENT = 'TEAMS_VIEW_RESCEU_EQUIPMENT',
    VIEW_PERSON_SHORT_INFO = 'VIEW_PERSON_SHORT_INFO',
    TEAMS_VIEW_ALL_STAFFING = 'TEAMS_VIEW_ALL_STAFFING',
    TEAMS_VIEW_REGIONAL_STAFFING = 'TEAMS_VIEW_REGIONAL_STAFFING',
    TEAMS_VIEW_COUNTRY_LEVEL_STAFFING = 'TEAMS_VIEW_COUNTRY_LEVEL_STAFFING',
    TEAMS_VIEW_OWN_STAFFING = 'TEAMS_VIEW_OWN_STAFFING',
    TEAMS_EDIT_ALL_STAFFING = 'TEAMS_EDIT_ALL_STAFFING',
    TEAMS_EDIT_OWN_STAFFING = 'TEAMS_EDIT_OWN_STAFFING',
    TEAMS_EDIT_REGIONAL_STAFFING = 'TEAMS_EDIT_REGIONAL_STAFFING',
    TEAMS_VIEW_ALL_EQUIPMENT = 'TEAMS_VIEW_ALL_EQUIPMENT',
    TEAMS_VIEW_REGIONAL_EQUIPMENT = 'TEAMS_VIEW_REGIONAL_EQUIPMENT',
    TEAMS_VIEW_COUNTRY_LEVEL_EQUIPMENT = 'TEAMS_VIEW_COUNTRY_LEVEL_EQUIPMENT',
    TEAMS_VIEW_OWN_EQUIPMENT = 'TEAMS_VIEW_OWN_EQUIPMENT',
    TEAMS_EDIT_ALL_EQUIPMENT = 'TEAMS_EDIT_ALL_EQUIPMENT',
    TEAMS_EDIT_OWN_EQUIPMENT = 'TEAMS_EDIT_OWN_EQUIPMENT',
    TEAMS_EDIT_REGIONAL_EQUIPMENT = 'TEAMS_EDIT_REGIONAL_EQUIPMENT',
}

export const ROLES_PERMISSIONS: Partial<Record<ROLES_LIST, PERMISSIONS_LIST[]>> = {
  [ROLES_LIST.ROLE_CLASSIFICATION_ADMIN_GLOBAL]: [
    PERMISSIONS_LIST.CLASSIFICATION_ACCESS_CLASSIFICATION_DASHBOARD,
    PERMISSIONS_LIST.CLASSIFICATION_APPROVE_EOI,
    PERMISSIONS_LIST.CLASSIFICATION_CREATE_EOI,
    PERMISSIONS_LIST.CLASSIFICATION_CREATE_MENTOR_PROFILE,
    PERMISSIONS_LIST.CLASSIFICATION_CREATE_VISIT,
    PERMISSIONS_LIST.CLASSIFICATION_UPDATE_EOI,
    PERMISSIONS_LIST.CLASSIFICATION_UPDATE_MENTOR_PROFILE,
    PERMISSIONS_LIST.CLASSIFICATION_UPDATE_PROCESS,
    PERMISSIONS_LIST.CLASSIFICATION_UPDATE_VISIT,
    PERMISSIONS_LIST.CLASSIFICATION_VIEW_ALL_EOIS,
    PERMISSIONS_LIST.CLASSIFICATION_VIEW_ALL_MENTOR_PROFILES,
    PERMISSIONS_LIST.CLASSIFICATION_VIEW_ALL_VISITS,
    PERMISSIONS_LIST.CLASSIFICATION_VIEW_PROCESSES,
    PERMISSIONS_LIST.TEAMS_CREATE_ORGANIZATION,
    PERMISSIONS_LIST.TEAMS_CREATE_TEAM,
    PERMISSIONS_LIST.TEAMS_FULL_EDIT_ORGANIZATION,
    PERMISSIONS_LIST.TEAMS_FULL_EDIT_TEAM
  ],
  [ROLES_LIST.ROLE_CLASSIFICATION_ADMIN_REGIONAL]: [
    PERMISSIONS_LIST.CLASSIFICATION_ACCESS_REGIONAL_CLASSIFICATION_DASHBOARD,
    PERMISSIONS_LIST.CLASSIFICATION_APPROVE_REGIONAL_EOI,
    PERMISSIONS_LIST.CLASSIFICATION_CREATE_REGIONAL_EOI,
    PERMISSIONS_LIST.CLASSIFICATION_CREATE_REGIONAL_MENTOR_PROFILE,
    PERMISSIONS_LIST.CLASSIFICATION_CREATE_REGIONAL_VISIT,
    PERMISSIONS_LIST.CLASSIFICATION_UPDATE_REGIONAL_EOI,
    PERMISSIONS_LIST.CLASSIFICATION_UPDATE_REGIONAL_MENTOR_PROFILE,
    PERMISSIONS_LIST.CLASSIFICATION_UPDATE_REGIONAL_PROCESS,
    PERMISSIONS_LIST.CLASSIFICATION_UPDATE_REGIONAL_VISIT,
    PERMISSIONS_LIST.CLASSIFICATION_VIEW_REGIONAL_EOIS,
    PERMISSIONS_LIST.CLASSIFICATION_VIEW_REGIONAL_MENTOR_PROFILES,
    PERMISSIONS_LIST.CLASSIFICATION_VIEW_REGIONAL_PROCESSES,
    PERMISSIONS_LIST.CLASSIFICATION_VIEW_REGIONAL_VISITS,
    PERMISSIONS_LIST.TEAMS_CREATE_REGIONAL_ORGANIZATION,
    PERMISSIONS_LIST.TEAMS_CREATE_REGIONAL_TEAM,
    PERMISSIONS_LIST.TEAMS_FULL_EDIT_REGIONAL_ORGANIZATION,
    PERMISSIONS_LIST.TEAMS_FULL_EDIT_REGIONAL_TEAM
  ],
  [ROLES_LIST.ROLE_ELECTION_ADMIN_GLOBAL]: [
    PERMISSIONS_LIST.AUTH_VIEW_ALL_USERS,
    PERMISSIONS_LIST.ELECTION_EDIT_CAMPAIGN,
    PERMISSIONS_LIST.ELECTION_ENDORSE_CANDIDATE,
    PERMISSIONS_LIST.ELECTION_NOMINATE,
    PERMISSIONS_LIST.ELECTION_NOMINATION_REVIEW,
    PERMISSIONS_LIST.ELECTION_VIEW_CAMPAIGN,
    PERMISSIONS_LIST.ELECTION_VIEW_CANDIDATE,
    PERMISSIONS_LIST.ELECTION_VIEW_FULL_VOTING_RESULTS,
    PERMISSIONS_LIST.ELECTION_VIEW_NOMINATIONS_BY_USER
  ],
  [ROLES_LIST.ROLE_ELECTION_ADMIN_REGIONAL]: [
    PERMISSIONS_LIST.AUTH_VIEW_REGIONAL_USERS,
    PERMISSIONS_LIST.ELECTION_EDIT_REGIONAL_CAMPAIGN,
    PERMISSIONS_LIST.ELECTION_ENDORSE_CANDIDATE,
    PERMISSIONS_LIST.ELECTION_NOMINATE,
    PERMISSIONS_LIST.ELECTION_NOMINATION_REVIEW,
    PERMISSIONS_LIST.ELECTION_VIEW_CANDIDATE,
    PERMISSIONS_LIST.ELECTION_VIEW_FULL_VOTING_RESULTS,
    PERMISSIONS_LIST.ELECTION_VIEW_REGIONAL_CAMPAIGN,
    PERMISSIONS_LIST.ELECTION_VIEW_NOMINATIONS_BY_USER
  ],
  [ROLES_LIST.ROLE_EMT_ROSTER_ADMIN_GLOBAL]: [
    PERMISSIONS_LIST.AUTH_VIEW_ALL_USERS,
    PERMISSIONS_LIST.CLASSIFICATION_CREATE_MENTOR_PROFILE,
    PERMISSIONS_LIST.CLASSIFICATION_UPDATE_MENTOR_PROFILE,
    PERMISSIONS_LIST.CLASSIFICATION_VIEW_ALL_MENTOR_PROFILES,
    PERMISSIONS_LIST.PERSON_ASSIGN_NFP,
    PERMISSIONS_LIST.PERSON_CREATE_PROFILE,
    PERMISSIONS_LIST.PERSON_DELETE_NFP_ASSIGNMENT,
    PERMISSIONS_LIST.PERSON_DELETE_PROFILE,
    PERMISSIONS_LIST.PERSON_EDIT_NFP_PROFILE,
    PERMISSIONS_LIST.PERSON_FULL_EDIT_PROFILES,
    PERMISSIONS_LIST.PERSON_VIEW_ALL_COUNTRIES,
    PERMISSIONS_LIST.PERSON_VIEW_ALL_PROFILES,
    PERMISSIONS_LIST.PERSON_VIEW_NFP_PROFILE,
    PERMISSIONS_LIST.TEAMS_ACCESS_DASHBOARD,
    PERMISSIONS_LIST.TEAMS_CREATE_ORGANIZATION,
    PERMISSIONS_LIST.TEAMS_CREATE_TEAM,
    PERMISSIONS_LIST.TEAMS_DELETE_ORGANIZATION,
    PERMISSIONS_LIST.TEAMS_DELETE_TEAM,
    PERMISSIONS_LIST.TEAMS_FULL_EDIT_ORGANIZATION,
    PERMISSIONS_LIST.TEAMS_FULL_EDIT_TEAM,
    PERMISSIONS_LIST.TEAMS_VIEW_ALL_ORGANIZATIONS,
    PERMISSIONS_LIST.TEAMS_VIEW_ALL_TEAMS
  ],
  [ROLES_LIST.ROLE_EMT_ROSTER_ADMIN_REGIONAL]: [
    PERMISSIONS_LIST.AUTH_VIEW_REGIONAL_USERS,
    PERMISSIONS_LIST.CLASSIFICATION_CREATE_REGIONAL_MENTOR_PROFILE,
    PERMISSIONS_LIST.CLASSIFICATION_UPDATE_REGIONAL_MENTOR_PROFILE,
    PERMISSIONS_LIST.CLASSIFICATION_VIEW_REGIONAL_MENTOR_PROFILES,
    PERMISSIONS_LIST.PERSON_ASSIGN_REGIONAL_NFP,
    PERMISSIONS_LIST.PERSON_CREATE_REGIONAL_PROFILE,
    PERMISSIONS_LIST.PERSON_DELETE_REGIONAL_NFP_ASSIGNMENT,
    PERMISSIONS_LIST.PERSON_DELETE_REGIONAL_PROFILE,
    PERMISSIONS_LIST.PERSON_EDIT_NFP_REGIONAL_PROFILE,
    PERMISSIONS_LIST.PERSON_FULL_EDIT_REGIONAL_PROFILES,
    PERMISSIONS_LIST.PERSON_VIEW_NFP_REGIONAL_PROFILE,
    PERMISSIONS_LIST.PERSON_VIEW_REGIONAL_COUNTRIES,
    PERMISSIONS_LIST.PERSON_VIEW_REGIONAL_PROFILES,
    PERMISSIONS_LIST.TEAMS_ACCESS_REGIONAL_DASHBOARD,
    PERMISSIONS_LIST.TEAMS_CREATE_REGIONAL_ORGANIZATION,
    PERMISSIONS_LIST.TEAMS_CREATE_REGIONAL_TEAM,
    PERMISSIONS_LIST.TEAMS_DELETE_REGIONAL_ORGANIZATION,
    PERMISSIONS_LIST.TEAMS_DELETE_REGIONAL_TEAM,
    PERMISSIONS_LIST.TEAMS_FULL_EDIT_REGIONAL_ORGANIZATION,
    PERMISSIONS_LIST.TEAMS_FULL_EDIT_REGIONAL_TEAM,
    PERMISSIONS_LIST.TEAMS_VIEW_REGIONAL_ORGANIZATIONS,
    PERMISSIONS_LIST.TEAMS_VIEW_REGIONAL_TEAMS
  ],
  [ROLES_LIST.ROLE_EMT_SECRETARIAT_GLOBAL]: [
    PERMISSIONS_LIST.CLASSIFICATION_CREATE_MENTOR_PROFILE,
    PERMISSIONS_LIST.CLASSIFICATION_UPDATE_MENTOR_PROFILE,
    PERMISSIONS_LIST.CLASSIFICATION_VIEW_ALL_MENTOR_PROFILES,
    PERMISSIONS_LIST.PERSON_ASSIGN_NFP,
    PERMISSIONS_LIST.PERSON_CREATE_PROFILE,
    PERMISSIONS_LIST.PERSON_EDIT_ALL_PROFILES,
    PERMISSIONS_LIST.PERSON_EDIT_NFP_PROFILE,
    PERMISSIONS_LIST.PERSON_VIEW_ALL_COUNTRIES,
    PERMISSIONS_LIST.PERSON_VIEW_ALL_PROFILES,
    PERMISSIONS_LIST.PERSON_VIEW_NFP_PROFILE,
    PERMISSIONS_LIST.TEAMS_ACCESS_DASHBOARD,
    PERMISSIONS_LIST.TEAMS_CREATE_ORGANIZATION,
    PERMISSIONS_LIST.TEAMS_CREATE_TEAM,
    PERMISSIONS_LIST.TEAMS_EDIT_ORGANIZATION,
    PERMISSIONS_LIST.TEAMS_EDIT_TEAM,
    PERMISSIONS_LIST.TEAMS_VIEW_ALL_ORGANIZATIONS,
    PERMISSIONS_LIST.TEAMS_VIEW_ALL_TEAMS
  ],
  [ROLES_LIST.ROLE_EMT_SECRETARIAT_REGIONAL]: [
    PERMISSIONS_LIST.CLASSIFICATION_CREATE_REGIONAL_MENTOR_PROFILE,
    PERMISSIONS_LIST.CLASSIFICATION_UPDATE_REGIONAL_MENTOR_PROFILE,
    PERMISSIONS_LIST.CLASSIFICATION_VIEW_REGIONAL_MENTOR_PROFILES,
    PERMISSIONS_LIST.PERSON_ASSIGN_REGIONAL_NFP,
    PERMISSIONS_LIST.PERSON_CREATE_REGIONAL_PROFILE,
    PERMISSIONS_LIST.PERSON_EDIT_NFP_REGIONAL_PROFILE,
    PERMISSIONS_LIST.PERSON_EDIT_REGIONAL_PROFILES,
    PERMISSIONS_LIST.PERSON_VIEW_NFP_REGIONAL_PROFILE,
    PERMISSIONS_LIST.PERSON_VIEW_REGIONAL_COUNTRIES,
    PERMISSIONS_LIST.PERSON_VIEW_REGIONAL_PROFILES,
    PERMISSIONS_LIST.TEAMS_ACCESS_REGIONAL_DASHBOARD,
    PERMISSIONS_LIST.TEAMS_CREATE_REGIONAL_ORGANIZATION,
    PERMISSIONS_LIST.TEAMS_CREATE_REGIONAL_TEAM,
    PERMISSIONS_LIST.TEAMS_EDIT_REGIONAL_ORGANIZATION,
    PERMISSIONS_LIST.TEAMS_EDIT_REGIONAL_TEAM,
    PERMISSIONS_LIST.TEAMS_VIEW_REGIONAL_ORGANIZATIONS,
    PERMISSIONS_LIST.TEAMS_VIEW_REGIONAL_TEAMS
  ],
  [ROLES_LIST.ROLE_GOVERNANCE_STRUCTURE_ADMIN_GLOBAL]: [
    PERMISSIONS_LIST.GOV_EDIT_ASSIGNMENTS,
    PERMISSIONS_LIST.GOV_EDIT_STRUCTURE,
    PERMISSIONS_LIST.GOV_VIEW_FULL_STRUCTURE
  ],
  [ROLES_LIST.ROLE_GOVERNANCE_STRUCTURE_ADMIN_REGIONAL]: [
    PERMISSIONS_LIST.GOV_EDIT_REGIONAL_ASSIGNMENTS,
    PERMISSIONS_LIST.GOV_EDIT_REGIONAL_STRUCTURE,
    PERMISSIONS_LIST.GOV_VIEW_FULL_STRUCTURE
  ],
  [ROLES_LIST.ROLE_GOVERNANCE_STRUCTURE_USER]: [
    PERMISSIONS_LIST.GOV_VIEW_STRUCTURE
  ],
  [ROLES_LIST.ROLE_MENTOR]: [
    PERMISSIONS_LIST.CLASSIFICATION_ACCESS_REGIONAL_CLASSIFICATION_DASHBOARD,
    PERMISSIONS_LIST.CLASSIFICATION_CREATE_MENTORED_PROCESSES_VISITS,
    PERMISSIONS_LIST.CLASSIFICATION_FIRST_VISIT,
    PERMISSIONS_LIST.CLASSIFICATION_MENTORSHIP,
    PERMISSIONS_LIST.CLASSIFICATION_PRE_VERIFICATION_PLANNED,
    PERMISSIONS_LIST.CLASSIFICATION_PRE_VERIFICATION_REQUEST,
    PERMISSIONS_LIST.CLASSIFICATION_PRE_VERIFICATION_VISIT,
    PERMISSIONS_LIST.CLASSIFICATION_SA_REVIEW,
    PERMISSIONS_LIST.CLASSIFICATION_UPDATE_MENTORED_PROCESSES_VISIT,
    PERMISSIONS_LIST.CLASSIFICATION_UPDATE_MY_MENTOR_PROFILE,
    PERMISSIONS_LIST.CLASSIFICATION_VERIFICATION_REQUEST,
    PERMISSIONS_LIST.CLASSIFICATION_VIEW_MENTORED_EOIS,
    PERMISSIONS_LIST.CLASSIFICATION_VIEW_MENTORED_PROCESSES,
    PERMISSIONS_LIST.CLASSIFICATION_VIEW_MENTORED_PROCESSES_VISITS,
    PERMISSIONS_LIST.CLASSIFICATION_VIEW_REGIONAL_MENTOR_PROFILES_BY_LINK,
    PERMISSIONS_LIST.CLASSIFICATION_WORKPLAN,
    PERMISSIONS_LIST.ELECTION_VIEW_NOMINEE,
    PERMISSIONS_LIST.ELECTION_VIEW_PUBLISHED_CAMPAIGN,
    PERMISSIONS_LIST.GOV_VIEW_STRUCTURE,
    PERMISSIONS_LIST.PERSON_EDIT_OWN_PROFILE,
    PERMISSIONS_LIST.PERSON_VIEW_NFP_REGIONAL_PROFILE,
    PERMISSIONS_LIST.PERSON_VIEW_REGIONAL_COUNTRIES,
    PERMISSIONS_LIST.PERSON_VIEW_REGIONAL_PROFILES_BY_LINK,
    PERMISSIONS_LIST.TEAMS_VIEW_REGIONAL_ORGANIZATIONS_BY_LINK,
    PERMISSIONS_LIST.TEAMS_VIEW_REGIONAL_TEAMS_BY_LINK
  ],
  [ROLES_LIST.ROLE_NFP]: [
    PERMISSIONS_LIST.CLASSIFICATION_ACCESS_REGIONAL_CLASSIFICATION_DASHBOARD,
    PERMISSIONS_LIST.CLASSIFICATION_VIEW_COUNTRY_LEVEL_EOIS,
    PERMISSIONS_LIST.CLASSIFICATION_VIEW_COUNTRY_LEVEL_MENTOR_PROFILES,
    PERMISSIONS_LIST.CLASSIFICATION_VIEW_COUNTRY_LEVEL_PROCESSES,
    PERMISSIONS_LIST.CLASSIFICATION_VIEW_COUNTRY_VISITS,
    PERMISSIONS_LIST.CLASSIFICATION_VIEW_REGIONAL_MENTOR_PROFILES_BY_LINK,
    PERMISSIONS_LIST.ELECTION_NOMINATE,
    PERMISSIONS_LIST.ELECTION_SELF_NOMINATE,
    PERMISSIONS_LIST.ELECTION_VIEW_NOMINATIONS_BY_USER,
    PERMISSIONS_LIST.ELECTION_VIEW_NOMINEE,
    PERMISSIONS_LIST.ELECTION_VIEW_PUBLISHED_CAMPAIGN,
    PERMISSIONS_LIST.ELECTION_VOTE,
    PERMISSIONS_LIST.ELECTION_WITHDRAW_NOMINATION,
    PERMISSIONS_LIST.GOV_VIEW_STRUCTURE,
    PERMISSIONS_LIST.PERSON_EDIT_NFP_OWN_PROFILE,
    PERMISSIONS_LIST.PERSON_EDIT_OWN_PROFILE,
    PERMISSIONS_LIST.PERSON_VIEW_COUNTRY_LEVEL_PROFILES,
    PERMISSIONS_LIST.PERSON_VIEW_NFP_REGIONAL_PROFILE,
    PERMISSIONS_LIST.PERSON_VIEW_REGIONAL_COUNTRIES,
    PERMISSIONS_LIST.PERSON_VIEW_REGIONAL_PROFILES_BY_LINK,
    PERMISSIONS_LIST.TEAMS_ACCESS_REGIONAL_DASHBOARD,
    PERMISSIONS_LIST.TEAMS_VIEW_COUNTRY_LEVEL_ORGANIZATIONS,
    PERMISSIONS_LIST.TEAMS_VIEW_COUNTRY_LEVEL_TEAMS,
    PERMISSIONS_LIST.TEAMS_VIEW_REGIONAL_ORGANIZATIONS_BY_LINK,
    PERMISSIONS_LIST.TEAMS_VIEW_REGIONAL_TEAMS_BY_LINK
  ],
  [ROLES_LIST.ROLE_TFP]: [
    PERMISSIONS_LIST.CLASSIFICATION_SA_SUBMIT,
    PERMISSIONS_LIST.CLASSIFICATION_VIEW_MY_ORGANIZATION_VISITS,
    PERMISSIONS_LIST.CLASSIFICATION_VIEW_ORGANIZATION_EOIS,
    PERMISSIONS_LIST.CLASSIFICATION_VIEW_ORGANIZATION_PROCESSES,
    PERMISSIONS_LIST.CLASSIFICATION_VIEW_REGIONAL_MENTOR_PROFILES_BY_LINK,
    PERMISSIONS_LIST.ELECTION_SELF_NOMINATE,
    PERMISSIONS_LIST.ELECTION_VIEW_NOMINATIONS_BY_USER,
    PERMISSIONS_LIST.ELECTION_VIEW_NOMINEE,
    PERMISSIONS_LIST.ELECTION_VIEW_PUBLISHED_CAMPAIGN,
    PERMISSIONS_LIST.ELECTION_VOTE,
    PERMISSIONS_LIST.ELECTION_WITHDRAW_NOMINATION,
    PERMISSIONS_LIST.GOV_VIEW_STRUCTURE,
    PERMISSIONS_LIST.PERSON_EDIT_OWN_PROFILE,
    PERMISSIONS_LIST.PERSON_VIEW_NFP_REGIONAL_PROFILE,
    PERMISSIONS_LIST.PERSON_VIEW_REGIONAL_COUNTRIES,
    PERMISSIONS_LIST.PERSON_VIEW_REGIONAL_PROFILES_BY_LINK,
    PERMISSIONS_LIST.TEAMS_EDIT_OWN_ORGANIZATION,
    PERMISSIONS_LIST.TEAMS_EDIT_OWN_TEAM,
    PERMISSIONS_LIST.TEAMS_VIEW_REGIONAL_ORGANIZATIONS_BY_LINK,
    PERMISSIONS_LIST.TEAMS_VIEW_REGIONAL_TEAMS_BY_LINK
  ],
  [ROLES_LIST.ROLE_TRAINING_EMT]: [
    PERMISSIONS_LIST.PERSON_EDIT_OWN_PROFILE,
    PERMISSIONS_LIST.PERSON_VIEW_REGIONAL_PROFILES_BY_LINK,
    PERMISSIONS_LIST.PERSON_VIEW_SHORT_INFO,
    PERMISSIONS_LIST.TEAMS_DEPLOYMENT_ADD_MY_REPORT,
    PERMISSIONS_LIST.TEAMS_DEPLOYMENT_DELETE_MY_REPORTS,
    PERMISSIONS_LIST.TEAMS_DEPLOYMENT_EDIT_MY_PROFILE,
    PERMISSIONS_LIST.TEAMS_DEPLOYMENT_GET_MY_PROFILES,
    PERMISSIONS_LIST.TEAMS_DEPLOYMENT_VIEW_MY_REPORTS,
    PERMISSIONS_LIST.TEAMS_EDIT_OWN_ORGANIZATION,
    PERMISSIONS_LIST.TEAMS_EDIT_OWN_TEAM
  ],
  [ROLES_LIST.ROLE_TRAINING_EMTCC]: [
    PERMISSIONS_LIST.PERSON_VIEW_ALL_PROFILES,
    PERMISSIONS_LIST.PERSON_VIEW_SHORT_INFO,
    PERMISSIONS_LIST.TEAMS_DEPLOYMENT_ADD_REPORT,
    PERMISSIONS_LIST.TEAMS_DEPLOYMENT_DELETE_REPORTS,
    PERMISSIONS_LIST.TEAMS_DEPLOYMENT_EDIT_PROFILE,
    PERMISSIONS_LIST.TEAMS_DEPLOYMENT_GET_ALL_PROFILES,
    PERMISSIONS_LIST.TEAMS_DEPLOYMENT_VIEW_REPORTS,
    PERMISSIONS_LIST.TEAMS_VIEW_ALL_ORGANIZATIONS,
    PERMISSIONS_LIST.TEAMS_VIEW_ALL_TEAMS
  ],
  [ROLES_LIST.ROLE_TRAINING_NFP]: [
    PERMISSIONS_LIST.PERSON_EDIT_NFP_OWN_PROFILE,
    PERMISSIONS_LIST.PERSON_EDIT_OWN_PROFILE,
    PERMISSIONS_LIST.PERSON_VIEW_REGIONAL_PROFILES_BY_LINK,
    PERMISSIONS_LIST.PERSON_VIEW_SHORT_INFO,
    PERMISSIONS_LIST.TEAMS_DEPLOYMENT_GET_ALL_PROFILES,
    PERMISSIONS_LIST.TEAMS_DEPLOYMENT_GET_COUNTRY_LEVEL_PROFILES,
    PERMISSIONS_LIST.TEAMS_DEPLOYMENT_VIEW_REPORTS,
    PERMISSIONS_LIST.TEAMS_VIEW_REGIONAL_ORGANIZATIONS_BY_LINK,
    PERMISSIONS_LIST.TEAMS_VIEW_REGIONAL_TEAMS_BY_LINK
  ]
};
