import { msalConfig, tokenRequestScopes } from "./authConfig";
import * as msal from "@azure/msal-browser";

export const msalInstance = new msal.PublicClientApplication(msalConfig);

msalInstance.addEventCallback((event: msal.EventMessage) => {
  if (event.eventType === msal.EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as msal.AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

export const fetchMsalToken = async (): Promise<string> => {
  const currentAccount = msalInstance?.getActiveAccount();
  try {
    if (currentAccount) {
      const accessTokenResponse = await msalInstance.acquireTokenSilent({
        scopes: tokenRequestScopes,
        account: currentAccount,
        redirectUri: process.env.REACT_APP_REDIRECT_URI
      });
      return accessTokenResponse.accessToken;
    } else {
      throw 'User is not initialized';
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};