import React from 'react';
import { AxiosError } from 'axios';

import { ErrorModal } from 'components/CommonComponents/Modals/ErrorModal/ErrorModal';

export interface IApiDetailedServerError {
    isServerError: boolean,
    isDetailedError: boolean,

    code: number,
    detail: string,
    instance: string,
    status: number,
    title: string,
    type: string,

    failedValidations?: Record<string, string> | null
    errorDetails?: Record<string, string> | null
}

export interface IApiSimpleError {
    isDetailedError: boolean,

    statusCode: number,
    message: string
}

export type ApiDetailedError = IApiDetailedServerError | IApiSimpleError;

const ERROR_RESPONSE_CODE = 500;

export const checkErrorIsApiError = (error: unknown): ApiDetailedError | null => {
  return getDetailedApiServerError(error) ?? getSimpleApiClientError(error);
};

export const getDetailedApiServerError = (error: any): IApiDetailedServerError | null => {
  if (!error || !error?.message) return null;

  try {
    let parsedError = error.message;

    if (error instanceof AxiosError) {
      parsedError = error.response?.data;
    } else if (typeof error.message === 'string') {
      parsedError = JSON.parse(error.message);
    }

    if (parsedError && parsedError.constructor === Object &&
            'code' in parsedError &&
            'detail' in parsedError &&
            'instance' in parsedError &&
            'status' in parsedError &&
            'title' in parsedError &&
            'type' in parsedError
    ) {
      return {
        ...parsedError,
        isServerError: error.statusCode >= 500,
        isDetailedError: true
      } as IApiDetailedServerError;
    }

    return null;

  } catch (error) {
    return null;
  }
};

const getSimpleApiClientError = (error: any): IApiSimpleError => {
   
  if (typeof error === "object" && "statusCode" in error && "message" in error) {
    return {
      ...error as IApiSimpleError,
      statusCode: error.statusCode,
      isServerError: error.statusCode >= 500,
      isDetailedError: false
    } as IApiSimpleError;

  } else {
    return {
      statusCode: 500,
      isDetailedError: false,
      message: "Sorry, something unexpected happened."
    };
  }
};

const ApiDetailedServerErrorModal: React.FC<{
    error: IApiDetailedServerError,
    onClose: () => void
}> = ({ error, onClose }) => {

  const errors = React.useMemo(() => {
    // TODO rewrite legacy code and should show default messages instead of server errors
    const failedValidationsKeys = Object.keys(error?.failedValidations ?? {});
    const errorDetailsKeys = Object.keys(error?.errorDetails ?? {});

    const result: Array<{ fieldKey: string, value: string }> = [];

    if (failedValidationsKeys.length) {
      for (let i = 0; i < failedValidationsKeys.length; i++) {
        if (error?.failedValidations && error?.failedValidations[failedValidationsKeys[i]]) {
          result.push({
            fieldKey: failedValidationsKeys[i],
            value: error.failedValidations[failedValidationsKeys[i]]
          });
        }
      }
    } else if (errorDetailsKeys.length) {
      for (let i = 0; i < errorDetailsKeys.length; i++) {
        if (error?.errorDetails && error?.errorDetails[errorDetailsKeys[i]]) {
          result.push({
            fieldKey: errorDetailsKeys[i],
            value: error.errorDetails[errorDetailsKeys[i]]
          });
        }
      }
    }
    return result;
  }, [error]);

  const errorMessage = error.code === ERROR_RESPONSE_CODE
    ? 'Server error occurred, please contact us at kimep@who.int'
    : error.detail;

  return (
    <ErrorModal
      open={error !== null}
      title={error.title ?? 'Error'}
      onClose={onClose}
      closeButtonText='Close'
      message={errors.length ? <>
        <p>Error: <b>{error.detail}</b></p>
        <p>Error Code: <b>{error.code}</b></p>
        {errors.map(item => (
          <React.Fragment key={item.fieldKey}>
            <p>Error Message: <b>{item.value}</b> (reason: {item.fieldKey})</p>
          </React.Fragment>
        ))}
      </> : <>
        <p>Error Code: <b>{error.code}</b></p>
        <p>Error Message: <b>{errorMessage}</b></p>
      </>}
    />
  );
};

const ApiSimpleErrorModal: React.FC<{
    error: IApiSimpleError | null,
    onClose: () => void
}> = ({ error, onClose }) => {

  return (
    <ErrorModal
      open={error !== null}
      title={'Error'}
      onClose={onClose}
      closeButtonText='Close'
      message={error?.message ? <>
        <p>Error Code: <b>{error.statusCode}</b></p>
        <p>Error Message: <b>{error.message}</b></p>
      </> : `Oops, Some Server Error: ${error?.statusCode}`}
    />
  );
};

export const ApiErrorModal: React.FC<{
    error: ApiDetailedError | null,
    onClose: () => void
}> = ({ error, onClose }) => {

  if (!error) return null;

  if (!error?.isDetailedError) {
    return (
      <ApiSimpleErrorModal
        error={error as IApiSimpleError}
        onClose={onClose}
      />
    );
  }

  return (
    <ApiDetailedServerErrorModal
      error={error as IApiDetailedServerError}
      onClose={onClose}
    />
  );
};
